import PortfolioGrid from "../portfolio/PortfolioGrid";

const Portfolio = () => {
  return (
    <>
      <h3 className="title-section">Portfolio page</h3>
      <PortfolioGrid />
    </>
  );
};

export default Portfolio;
