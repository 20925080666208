import Resume from "../resume/Resume";

const ResumePage = () => {
  return (
    <>
      <h1 className="title-section">Resume</h1>
      <Resume />
    </>
  );
};

export default ResumePage;
