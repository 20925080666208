import AboutMe from "../about/AboutMe";

const AboutMePage = () => {
  return (
    <>
      <h1 className="title-section">About me</h1>
      <AboutMe />
    </>
  );
};

export default AboutMePage;
