import Transcript from "../transcript/Transcript";

const TranscriptPage = () => {
  return (
    <>
      <h1 className="title-section">Transcript</h1>
      <Transcript />
    </>
  );
};

export default TranscriptPage;
